// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-help-advice-tsx": () => import("./../src/templates/help-advice.tsx" /* webpackChunkName: "component---src-templates-help-advice-tsx" */),
  "component---src-templates-help-advice-single-tsx": () => import("./../src/templates/help-advice-single.tsx" /* webpackChunkName: "component---src-templates-help-advice-single-tsx" */),
  "component---src-templates-home-designs-tsx": () => import("./../src/templates/home-designs.tsx" /* webpackChunkName: "component---src-templates-home-designs-tsx" */),
  "component---src-templates-hl-packages-tsx": () => import("./../src/templates/hl-packages.tsx" /* webpackChunkName: "component---src-templates-hl-packages-tsx" */),
  "component---src-templates-home-designs-summary-tsx": () => import("./../src/templates/home-designs-summary.tsx" /* webpackChunkName: "component---src-templates-home-designs-summary-tsx" */),
  "component---src-templates-hl-packages-summary-tsx": () => import("./../src/templates/hl-packages-summary.tsx" /* webpackChunkName: "component---src-templates-hl-packages-summary-tsx" */),
  "component---src-templates-help-advice-categories-tsx": () => import("./../src/templates/help-advice-categories.tsx" /* webpackChunkName: "component---src-templates-help-advice-categories-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

